export const environment = {
  production: true,
  index: false,
  urlApi: 'https://dev-core.myralis.com.br/api',
  bugsnagKey: '327dbb0b7e597ad193b6e494bdb77a69',
  googleClientID:
    '66195095407-5rupgn98e79mnunrrdslv33puk7vcmrr.apps.googleusercontent.com',
  facebookClientID: '197097275333763',
  oneSignalRestApiKey: 'M2EzYjU3ZGMtZWQ2NS00NmQ1LTk5YzItMDY3NzIyMGM2ZGEw',
  oneSignalAppId: 'a62679dd-0ebc-47d4-9768-abb76f08d82a'
};
